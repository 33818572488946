import al from '../../media/al.png';
import al_h from '../../media/al_h.png';

import ph from '../../media/ph.png';
import ph_h from '../../media/ph_h.png';

import psa from '../../media/psa.png';
import psa_h from '../../media/psa_h.png';

import cr from '../../media/cr.png';
import cr_h from '../../media/cr_h.png';

import {
  ItemTitle,
  ItemCont,
  ItemLink,
  ItemLinks,
  ItemDesc,
  ItemPic,
  Item,
  ItemContainer,
  SectionContainer,
} from "./atoms";

function Speaking() {
  return (
    <SectionContainer>
      <ItemContainer>

        <Item>
          <ItemPic img={al} imgh={al_h} alt="Agda and Lean"/>
          <ItemDesc>
            <ItemTitle>Agda and Lean: <br/>functional programming meets proving</ItemTitle>
            <ItemCont>Lambda World 2024</ItemCont>
            <ItemLinks items = {[
              {
                href:"https://github.com/j-mayoral/agda-lean-lambda-world",
                link:"repo",
              },
              {
                href:"https://docs.google.com/presentation/d/1NWp_1ffK-IcxGtZeEvj86LA5DFUDJWDWXd9mQpMFYpQ/edit?usp=sharing",
                link:"slides",
              },
            ]}/>
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={ph} imgh={ph_h} alt="Persistent Homology"/>
          <ItemDesc>
            <ItemTitle>Persistent Homology: <br/>shaping the new unsupervised learning</ItemTitle>
            <ItemCont>J On The Beach 2024</ItemCont>
            <ItemLinks items = {[
              {
                href:"https://www.youtube.com/watch?v=qM_QdtjgEsk",
                link:"video",
              },
              {
                href:"https://docs.google.com/presentation/d/1BNPmekFNrEYY6dYc22l1h26EsX84YlKsoqdg4ajGgJU/edit?usp=sharing",
                link:"slides",
              },
            ]}/>
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={psa} imgh={psa_h} alt="Probabilistic Streaming Algorithms"/>
          <ItemDesc>
            <ItemTitle>Probabilistic Streaming Algorithms: <br/>accuracy and speed at low cost</ItemTitle>
            <ItemCont>J On The Beach 2023</ItemCont>
            <ItemLinks items = {[
              {
                href:"https://www.youtube.com/watch?v=GjSb-0PHS40",
                link:"video",
              },
              {
                href:"https://docs.google.com/presentation/d/14ExAKuA5YTCVV2QvXmF7y3-Ud7XsGwgzcUR-F8exjHA/edit?usp=sharing",
                link:"slides",
              },
            ]}/>
            <ItemCont>Commit Conf 2024 (Spanish)</ItemCont>
            <ItemLinks items = {[
              {
                href:"https://www.youtube.com/watch?v=g_XTcYUZ1pg",
                link:"video",
              },
              {
                href:"https://docs.google.com/presentation/d/1Z1t8ZpEpQoE2D_B2jwL_-C4ki_AmfppV5j52HNqOTt8/edit?usp=sharing",
                link:"slides",
              },
            ]}/>
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={cr} imgh={cr_h} alt="La Razón Doble"/>
          <ItemDesc>
            <ItemTitle>La Razón Doble</ItemTitle>
            <ItemCont>Semana de la Ciencia Indignada <br/>Universidad Complutense de Madrid</ItemCont>
            <ItemLink
              href="https://www.youtube.com/watch?v=o7b47gxOMsU"
              link="video"
            />
          </ItemDesc>
        </Item>

      </ItemContainer>
    </SectionContainer>
  )
}

export default Speaking;